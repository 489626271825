    import { CellScore } from "./CellScore";
    import { Piece, Cols } from "./globs";

    export class Cell
    {
        public index : number;
        public row : number;
        public col : number;
        public piece : Piece = Piece.Free;
        public vectorIndex : number[] = [ -1,-1,-1,-1 ];       // The 4 Vectors -1 = no Vector this Direction
        public vectorStoneIndex : number[] = [ -1, -1, -1, -1] ; // Index of Stone for the 4 Vectors
        
        public cellScores : CellScore[] = new Array(2);
        
        public constructor(index : number)
        {
            this.index = index;
            this.row = (index / Cols);
            this.col = (index % Cols);
            for(let dir = 0; dir < 4; dir++)
            {
                this.vectorIndex[dir] = -1;
            }
            this.cellScores[0] = new CellScore();
            this.cellScores[1] = new CellScore();
            this.InitScores();
        }

        public InitScores() : void
        {
            this.piece = Piece.Free;

            for (let player = 0; player < 2; player++)
            {
                var dirs : number = 0;
                for (let dir = 0; dir < 4; dir++)
                {
                    if (this.vectorIndex[dir] >= 0)
                    {
                        this.cellScores[player].detail[dir] = 1;
                        dirs++;
                    }
                }
                this.cellScores[player].score = dirs;
                // boardScore[player] = (boardScore[player] + dirs);
             }
        }
    }
