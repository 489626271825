export class Shapes
{
    private static drawFace( ctx : CanvasRenderingContext2D, cx : number, cy : number, radius : number) : void
    {
        ctx.beginPath();
        ctx.arc(cx, cy, radius, 0, 2 * Math.PI);
        ctx.lineWidth = radius / 8 ;      // 7;
        ctx.strokeStyle = 'Black';
        ctx.stroke();
        ctx.fillStyle = "red";
        ctx.fill();
    }

    private static drawSmile(ctx : CanvasRenderingContext2D, cx : number, cy : number, radius : number)
    {
        var sx = cx;
        var sy =  cy - (radius / 6);       //  160
        var radiusSmile = radius / 4 * 3 ;  //  40;
        var startAngle = 0.1 * Math.PI;
        var endAngle = 0.9 * Math.PI;

        ctx.beginPath();
        ctx.arc(cx, sy, radiusSmile, startAngle, endAngle);
        ctx.lineWidth = radius / 10 ;      // 7;
        // line color
        ctx.strokeStyle = 'black';
        ctx.stroke();
    }

    private static drawEyes(ctx : CanvasRenderingContext2D, cx : number, cy : number, radius : number) : void 
    {
        // right Eye
        var centerX = cx + radius / 3.5;  // 40;
        var centerY = cy - radius / 4 ;  // 0; ????
        var xEyeRadius =  radius / 8 ;    //  10;
        var yEyeRadius =  radius / 5 ;
        // draw ellipse 
        ctx.beginPath();
        ctx.ellipse(centerX, centerY, xEyeRadius, yEyeRadius, 0, 0, 2 * Math.PI);

        // apply styling
        ctx.fillStyle = 'black';
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'black';
        ctx.stroke();

        //left eye
        var centerX = cx - radius / 3.5;  // -40;
        // draw ellipse 
        ctx.beginPath();
        ctx.ellipse(centerX, centerY, xEyeRadius, yEyeRadius, 0, 0, 2 * Math.PI);
        // apply styling
        ctx.fillStyle = 'black';
        ctx.fill();
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'black';
        ctx.stroke();
    }

    public static drawHappyFace(ctx : CanvasRenderingContext2D, cx : number, cy : number, radius : number) : void
    {
        Shapes.drawFace(ctx , cx, cy, radius );
        Shapes.drawEyes(ctx, cx, cy, radius);
        Shapes.drawSmile(ctx,cx,cy, radius);
    }

    public static drawCogWheel(context : CanvasRenderingContext2D, cx: number, cy: number, 
                                notches : number, radiusO : number, radiusI : number, 
                                radiusH: number, taperO : number, taperI : number ) : void 
    {
        var pi2 : number     = 2 * Math.PI;             // cache 2xPI (360deg)
        var angle : number  = pi2 / (notches * 2);      // angle between notches
        var taperAI : number = angle * taperI * 0.005;  // inner taper offset (100% = half notch)
        var taperAO : number = angle * taperO * 0.005;  // outer taper offset
        var a : number = angle;                         // iterator (angle)
        var toggle : boolean  = false;                  // notch radius level (i/o)

        context.fillStyle = "black"; // default
        context.beginPath();
        context.moveTo(cx + radiusO * Math.cos(taperAO), cy + radiusO * Math.sin(taperAO));
        // loop
        for (; a <= pi2; a += angle) 
        {   
            // draw inner to outer line
            if (toggle) {
                context.lineTo(cx + radiusI * Math.cos(a - taperAI),
                        cy + radiusI * Math.sin(a - taperAI));
                context.lineTo(cx + radiusO * Math.cos(a + taperAO),
                        cy + radiusO * Math.sin(a + taperAO));
            }
            // draw outer to inner line
            else {
                context.lineTo(cx + radiusO * Math.cos(a - taperAO),  // outer line
                        cy + radiusO * Math.sin(a - taperAO));
                context.lineTo(cx + radiusI * Math.cos(a + taperAI),  // inner line
                        cy + radiusI * Math.sin(a + taperAI));
            }     
            // switch level
            toggle = !toggle;
        }
        // close the final line
        context.closePath();
        context.fill();

        // "erase" mode (term simplified)
        // this.context.globalCompositeOperation = 'destination-out';

        // create circle (full arc)
        context.fillStyle = "red";
        context.beginPath();
        context.moveTo(cx + radiusH, cy);
        context.arc(cx, cy, radiusH, 0, pi2);
        context.closePath();

        // creates the hole
        context.fill();
    }  

    public static drawRegularPolygon(ctx: CanvasRenderingContext2D, n: number, x: number, y: number, r: number): void
     {
        const angle = (Math.PI * 2) / n; // Calculate the angle between each vertex 
        ctx.fillStyle = "black";
        ctx.beginPath();
        ctx.moveTo(x + r, y); // Move the starting point to the first vertex
        for (let i = 1; i <= n; i++) {
           const vertexX = x + r * Math.cos(i * angle); // Calculate the x-coordinate of the current vertex
           const vertexY = y + r * Math.sin(i * angle); // Calculate the y-coordinate of the current vertex
           ctx.lineTo(vertexX, vertexY); // Connect the current vertex to the previous vertex
        }
        ctx.closePath();
        ctx.fill(); // Draw the polygon outline
     }
    } 
