import { Piece, Player, ActionType, Rows, Cols , VectorDir, BoardCells, theFourth, settable} from "./globs";
import { Cell } from "./Cell";
import { CellScore } from "./CellScore";
import { FourVector } from "./FourVector";
import { BoardAction } from "./BoardAction";
import { PatternLookup } from "./patternLookup";
import { Util } from "./Util";
import { Board } from "./Board";

export class Searcher extends Board
{
    private nodeCount : number = 0; // counter of explored nodes.
    private lineCount : number = 0;
    private maxDepth : number = 0;

    public constructor()
    {
        super();
    }
    public solve(/*TraceItem? root,*/ weak : boolean = false) : number
    {

        this.nodeCount = 0;
        if (weak)
            return this.maximize(/*P,*/ -1, 1, 0 /*, root*/);
        else
            // -alpha max possible moves / 2,  +beta max possible Moves / 2 
            return this.maximize(-BoardCells / 2, BoardCells / 2, 0 /*, root*/);
    }

    public getNodeCount() : number
    {
        return this.nodeCount;
    }

    /// <summary>Alpha Beta Search Initial Maximizer.
    /// Prerequisite: Board not full.</summary>
    /// <param name="maxDepth">Search Depth.</param>
    /// <param name="parent">Trace Root Item. Null if no Traciong</param>
    /// <returns>Search Result 0 = Tie < 0 = loose, > 0 Win, Column</returns>
    public   Search(maxDepth : number /*, TraceItem? parent = null*/) : [abResult : number, col : number, nodes : number]
    {
        //Debug.Assert(maxDepth > 0 && maxDepth <= 42);
        // Debug.Assert(nbMoves < Board.BoardCellCount);   // If Game over no Search possible
        this.maxDepth = maxDepth;
        let alpha : number = -BoardCells / 2;
        let beta : number = BoardCells / 2;
        this.nodeCount = 0;

        // if(parent != null) parent.childs.Clear();

        let maResult : [isWin : boolean, movable : number, loosingNext : number] = this.MoveAnalyzer();
        let movableCol = maResult[1];
        let loosingNext = maResult[2];

        // if True Winner. else set movable and loosing next
        if (maResult[0] == true)
        {
            // if (parent != null) parent.source = rcSource.MaxWin;
            let res : number = (BoardCells + 1 - this.nbMoves) / 2;
            return [res, movableCol, this.nodeCount];   // Winning with n Moves to Board Full
        }

        //if (movableCol == 0)
        //{
        //    Debug.Assert(loosingNext != 0);
        //}
        movableCol |= loosingNext;  // only possible Move(s) 
        let max : number = (Cols * Rows - 1 - this.nbMoves) / 2;   // upper bound of our score as we cannot win immediately
        let originalBeta : number = beta; // For Trace
        let resCol : number = 0;
        for (let x = 0; x < Cols; x++) // compute the score of all possible next move and keep the best one
        {
            if ((this.ColBits[x] & movableCol) != 0)
            {
                // TraceItem? trace = null;
                this.play(this.columnOrder[x]);
                //if (parent != null)
                //{
                //    trace = new TraceItem(parent, +1, 0, maxDepth, alpha, beta, originalBeta, JustSet(columnOrder[x]));
                //    parent.childs.Add(trace);
                //}
                let score : number = this.minimize(alpha, beta, 1 /*, trace*/);		// If current player plays col x, his score will be the opposite of opponent's score after playing col x
                // if (trace != null) trace.rc = score;
                this.unDo(this.columnOrder[x]);
                if (score >= beta)
                {
                    // if (parent != null) parent.source = rcSource.MaxPrun;
                    return [score, this.columnOrder[x], this.nodeCount];  // prune the exploration if we find a possible move better than what we were looking for.
                }
                if (score > alpha)
                {
                    alpha = score; // reduce the [alpha;beta] window for next exploration, as we only 
                                   // need to search for a position that is better than the best so far.
                    resCol = this.columnOrder[x];
                }
            }
        }
        // if (parent != null) parent.source = rcSource.MaxRes;
        return [alpha, resCol, this.nodeCount];
    }



    /// <summary>Alpha Beta Search Maximizer. Do Even Moves.</summary>
    /// <param name="P">The Board. P: Position</param>
    /// <param name="alpha">Max Value from last maximize. Initial: -Maximum Value</param>
    /// <param name="beta">Min Value from last minimize. Initial: Maximum Value</param>
    /// <returns>Position Value. 0 = Draw. + Winning. - Loosing</returns>
    private maximize(alpha : number, beta : number, depth : number /*, TraceItem? parent = null*/) : number
    {
        this.nodeCount++;
        // check for draw game.Board full
        if (this.nbMoves == Cols * Rows)
        {
            // if (parent != null) parent.source = rcSource.MaxDraw;
            return 0;
        }

        let maResult : [isWin : boolean, movable : number, loosingNext : number] = this.MoveAnalyzer();
        let movableCol = maResult[1];
        let loosingNext = maResult[2];

        // if True Winner. else set movable and loosing next
        if (maResult[0] == true)
        {
            // if (parent != null) parent.source = rcSource.MaxWin;
            return (Cols * Rows + 1 - this.nbMoves) / 2;   // Winning with n Moves to Board Full
        }

        if (movableCol == 0)
        {
            // Debug.Assert(loosingNext != 0);
            // if (parent != null) parent.source = rcSource.MaxLNxt;
            return -(Cols * Rows - this.nbMoves) / 2;
        }

        if (depth >= this.maxDepth)
            return 0;

        let max : number = (Cols * Rows - 1 - this.nbMoves) / 2;   // upper bound of our score as we cannot win immediately
        let originalBeta : number = beta;
        if (beta > max)
        {
            beta = max;                      // there is no need to keep beta above our max possible score.
            if (alpha >= beta)
            {
                // if (parent != null) parent.source = rcSource.MaxMPrn;
                return beta;  // prune the exploration if the [alpha;beta] window is empty.
            }
        }

        for (let x = 0; x < Cols; x++) // compute the score of all possible next move and keep the best one
        {
            if ((this.ColBits[x] & movableCol) != 0)
            {
                // TraceItem? trace = null;
                this.play(this.columnOrder[x]);
                //if (parent != null)
                //{
                //    trace = new TraceItem(parent, +1, depth, maxDepth, alpha, beta, originalBeta, JustSet(columnOrder[x]));
                //    parent.childs.Add(trace);
                //}
                let score : number = this.minimize(alpha, beta, depth + 1 /*, trace*/ );		// If current player plays col x, his score will be the opposite of opponent's score after playing col x
                //if (trace != null) trace.rc = score; 
                this.unDo(this.columnOrder[x]);
                if (score >= beta)
                {
                    // if (parent != null) parent.source = rcSource.MaxPrun;
                    return score;  // prune the exploration if we find a possible move better than what we were looking for.
                }
                if (score > alpha) alpha = score; // reduce the [alpha;beta] window for next exploration, as we only 
                                                  // need to search for a position that is better than the best so far.
            }
        }
        // if (parent != null) parent.source = rcSource.MaxRes;
        return alpha;
    }

    private minimize(alpha : number, beta : number, depth : number) : number
    {
        // Debug.Assert(alpha < beta);
        this.nodeCount++;
        // check for draw game. Board full.
        if (this.nbMoves == Cols * Rows)
        {
            // if (parent != null) parent.source = rcSource.MinDraw;
            return 0;
        }
 
        let maResult : [isWin : boolean, movable : number, loosingNext : number] = this.MoveAnalyzer();
        let movableCol = maResult[1];
        let loosingNext = maResult[2];

        if (maResult[0] == true)
        {
            // if (parent != null) parent.source = rcSource.MinWin;
            return -((Cols * Rows + 1 - this.nbMoves) / 2);   // Winning with n Moves to Board Full
        }

        if (movableCol == 0)
        {
            // Debug.Assert(loosingNext != 0);
            // if (parent != null) parent.source = rcSource.MinLNxt;
            return (Cols * Rows - this.nbMoves) / 2;
        }
        
        let max : number = -((Cols * Rows - 1 - this.nbMoves) / 2);  // upper bound of our score as we cannot win immediately
        let originalAlpha : number = alpha;
        if (alpha < max)
        {
            alpha = max;                      // there is no need to keep beta above our max possible score.
            if (beta <= alpha)
            {
                // if (parent != null) parent.source = rcSource.MinMPrn;
                return -alpha;  // prune the exploration if the [alpha;beta] window is empty.
            }
        }

        if (depth >= this.maxDepth)
            return 0;

        for (let x = 0; x < Cols; x++) // compute the score of all possible next move and keep the best one
        {
            if ((this.ColBits[x] & movableCol) != 0)
            {
                //TraceItem? trace = null;
                this.play(this.columnOrder[x]);
                //if (parent != null)
                //{
                //    trace = new TraceItem(parent, -1, depth, maxDepth, alpha, beta, originalAlpha, JustSet(columnOrder[x]));
                //    parent.childs.Add(trace);
                //}
                let score : number = this.maximize(alpha, beta, depth + 1/*, trace*/);     // If current player plays col x, his score will be the opposite of opponent's score after playing col x
                // if (trace != null) { trace.rc = score; }
                this.unDo(this.columnOrder[x]);
                if (score <= alpha)
                {
                    // if (parent != null) parent.source = rcSource.MinPrun;
                    return score;  // prune the exploration if we find a possible move better than what we were looking for.
                }
                if (score < beta) beta = score; // reduce the [alpha;beta] window for next exploration, as we only 
                                                  // need to search for a position that is better than the best so far.
            }
        }
        // if (parent != null) parent.source = rcSource.MinRes;
        return beta;
    }
}
