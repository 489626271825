    import { Piece, theFourth} from "./globs";

    type Counts = [outs : number, eng : number, player : number, emp : number];

    export class PatternLookup
    {
        // private const empty = 0;
        // private const engine = 1;
        // private const opponent = 2;
        // private const outside = 3;

        // 1st Index = 7 Color pattern == 14 Bits
        // 2nd Inder 0 = Engine, 1 = Opponent View
        // 3rd Index 0 - 6  Directional Value  (4 Direction of Left & Right Side)
        public static PatternLookupTable : number[] = new Array(16384 * 2 * 8); 
        
        static PatternLookup()
        {
            PatternLookup.SetupPatternTable();
        }

        public static SetupPatternTable() : void
        { 
            for(let v = 0; v <  16384; v++ ) 
            {
                var vectorVals : number[] = PatternLookup.GetVectorVals(Piece.Engine, v); 
                for(let i = 0; i < vectorVals.length;i++)
                {
                    PatternLookup.PatternLookupTable[v * 16 + 0 + i] = vectorVals[i];
                }
                // Debug.WriteLine("SetupPatternTable.v: " + v.ToString() + " Engine set");
                vectorVals = PatternLookup.GetVectorVals(Piece.Opponent, v);
                for (let i = 0; i < vectorVals.length; i++)
                {
                    PatternLookup. PatternLookupTable[v * 16 + 8 + i] = vectorVals[i];
                }
                // Debug.WriteLine("SetupPatternTable.v: " + v.ToString() + " Opponent set");
            }
            for (let v = 0; v < 8; v++)
            {
                for (let pl = 0; pl < 2; pl++)
                {
                    var bits : string = ""; 
                    for (let bit = 0; bit < 8; bit++)
                    {
                        if (bits.length > 0) bits += ", ";
                        bits += String(PatternLookup.PatternLookupTable[v * 16 + pl * 8 + bit]);
                    }
                    // console.log("V: " + String(v) + " Pl: " + String(pl) + " Bits: " + bits);
                }
            }
            return;
        }

        private static GetVectorVals(view : Piece, v : number) : number[]
        {
            var vals : number[] = [0,0,0,0,0,0,0,0];
            var stones : number[] = [0,0,0,0,0,0,0,0];
            var emptyValue : number = 0;
            var rightOut : number = 0;
            var leftOut : number = 0;
            var v1 : number = v;

            // assign the 8 double Bits (colors) to 8 vals
            // Debug.WriteLine("GetVectorVals at Entry. View: " + view.ToString() + " v: " + v.ToString() + " rightOut: " + rightOut.ToString() + "  leftOut: " + leftOut.ToString());
            for (let i = 0; i < 8; i++, v1 >>= 2) 
            {
                var bits : number = v1 & 0x03;
                vals[i] = bits;
            }
            if (vals[0] == 3)                       // outside
                rightOut = 1;
            if (vals[0] == 3 && vals[1] == 3)       // outside
                rightOut = 2;
            if (vals[6] == 3)                       // outside
                leftOut = 1;
            if (vals[6] == 3 && vals[5] == 3)       // outside 
                leftOut = 2;
            if (vals[6] == 3 && vals[5] == 3 && vals[4] == 3) // outzside
                leftOut = 3;
            if ((rightOut + leftOut) > 3)
            {
                stones[7] = 1; // invalid Entry
            }
            // Debug.WriteLine("GetVectorVals View: " + view.ToString() + " v: " + v.ToString() + " rightOut: " + rightOut.ToString() + "  leftOut: " + leftOut.ToString());
            for (let slider = rightOut; slider < 7 - (leftOut + 3); slider++)
            {
                //type Counts = [outs : number, eng : number, player : number, emp : number];
                var counts : Counts = PatternLookup.GetCounts(vals, slider);
                if (counts[0] > 0)      // outs
                {
                    stones[7] = 1;      // Mark a Invalid
                    return stones;                
                }
                if (counts[1] == 4)     // eng
                {
                    stones[7] = 2; 
                    return stones;
                }
                if (counts[2] == 4)     // player
                {
                    stones[7] = 3;
                    return stones;
                }
                if (counts[1] > 0 && counts[2] > 0)  // Eng , Player
                {
                    continue;
                }
                if (counts[3] == 4)         // empty
                {
                    PatternLookup.SetStones(slider, 1, vals, stones);
                }
                if (view == Piece.Engine)
                {
                    switch(counts[1])       // eng
                    {
                        case 0:
                            emptyValue = 0; break;
                        case 1:
                            emptyValue = 4; break;
                        case 2:
                            emptyValue = 16; break;
                        case 3:
                            emptyValue = theFourth; break;  // 128
                    }
                    PatternLookup.SetStones(slider, emptyValue, vals, stones);
                }
                else
                {
                    switch (counts[2])      // player
                    {
                        case 0:
                            emptyValue = 0; break;
                        case 1:
                            emptyValue = 4; break;
                        case 2:
                            emptyValue = 16; break;
                        case 3:
                            emptyValue = theFourth; break; // 128
                    }
                    PatternLookup.SetStones(slider, emptyValue, vals, stones);
                }
            }
            return stones;
        }

        private static SetStones(slider : number, val : number, vals : number[], stones : number[]) : void
        {
            for (let i = 0; i < 4; i++) 
            {
                if (stones[i + slider] < val && vals[i + slider] == 0)  // empty
                {
                    stones[i + slider] = val;
                }
            }
        }

        private static GetCounts(bits : number[], slider : number) : Counts
        {
            var outs = 0; 
            var eng = 0;
            var player = 0;
            var emp = 0;
            for (let i = 0; i < 4; i++) 
            {
                var bit : number = bits[i + slider];
                switch(bit)
                {
                    case 0:             // empty
                        emp++;
                        break;
                    case 1:             // engine
                        eng++;
                        break;
                    case 2:             // opponent:
                        player++;
                        break;
                    case 3:             // outside
                        outs++;
                        break;
                    default:
                        throw new Error("Has invalid Value: " + String(bit));
                }
            }
            const cnts : Counts = [outs, eng, player, emp];
            return cnts; 
        }
    }

