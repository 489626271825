export enum State 
{
    Idle = 0,
    WaitClick = 1,
    Searching = 2,
    GameOver = 3,
}

export enum TerminationCode
{
    Ready = 0,
    Done = 1,
    TimeOut = 2
}

export enum MinMax  // Strike on C++ 
{
    None = 0,
    Min = 1,
    Max = 2
}

export enum PieceColor 
{
    /// <summary>The Cross is Free.</summary>
    Free = 0,

    /// <summary>The Cross has a black Stone.</summary>
    Black = 1,

    /// <summary>The Cross has a white Stone.</summary>
    White = 2
}

// ************ Board Level Enums *************

export const Rows = 6;
export const Cols = 7;
export const BoardCells = Rows * Cols;
export const theFourth = 128;

export enum Piece
{
    Free = 0,
    Engine = 1,
    Opponent = 2
}

export enum Player
{
    Engine = 0,
    Opponent = 1
}

export enum VectorDir
{
    verticalDir = 0,
    leftRightDir = 1,
    rightLeftDir = 2,
    horizontalDir = 3
}

export enum ActionType
{
    None = 0,       // Invalid
    Add = 1,        // Set Stone (Stone 1 - 9) at Index
    AddTrace = 2,   // Make Trace Entry Visible for selected trace entry
    UndoAdd = 3     // For Search Engine  Set / Undo. Not used in Stones. 
}

export var settable : [boolean, number, number];  // Return Tuple of MoveAnalyser 

