import { VectorDir } from "./globs";

export class FourVector
{
    public readonly direction : VectorDir;
    public readonly length : number;            // Nummer of Inside Stones of this Vector
    public readonly incr : number;              // Incr to Next Stone. Starting at FirstStoneIndex
    public readonly FirstStoneIndex : number;   // first stone Index on Board
    public readonly FirstBitIndex : number;
    public readonly firstCol : number;
    public readonly FirstRow : number;
    public readonly emptyVector : number;      //  [Out] piece, piece ... [Out]

    public constructor(direction : VectorDir, length : number, incr : number, firstStoneIndex : number, 
           firstBit : number,  firstCol : number, firstRow : number, emptyVector : number)
    {
        this.direction = direction;
        this.length = length;
        this.incr = incr;
        this.FirstStoneIndex = firstStoneIndex;
        this.FirstBitIndex = firstBit;
        this.firstCol = firstCol;
        this.FirstRow = firstRow;
        this.emptyVector = emptyVector;
    }
}
