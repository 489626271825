    import { Piece, Player, ActionType, Rows, Cols , VectorDir, BoardCells, theFourth, settable} from "./globs";
    import { Cell } from "./Cell";
    import { CellScore } from "./CellScore";
    import { FourVector } from "./FourVector";
    import { BoardAction } from "./BoardAction";
    import { PatternLookup } from "./patternLookup";
    import { Util } from "./Util";
 
    export class Board
    {
        // for Bit Pattern matching, [bit number (0-6, Piece]
        public static readonly bits : number[][] = [ [0x0000,0x0001,0x0002, 0x0000 ], [0x0000,0x0004,0x0008, 0x0000],
                                         [0x0000,0x0010,0x0020, 0x0000 ], [0x0000,0x0040,0x0080, 0x0000],
                                         [0x0000,0x0100,0x0200, 0x0000 ], [0x0000,0x0400,0x0800, 0x0000],
                                         [0x0000,0x1000,0x2000, 0x0000 ] ];

        public dirIncr : readonly number[] = [ Cols, Cols + 1, Cols - 1, 1 ];

        public  board : Cell[] = new Array(Rows * Cols);

        /// <summary>Number of Stones in a Column.</summary>
        public height : number[] = new Array(Cols);  // Vertial Number of Set Stones per Column
        
        public readonly columnOrder : number[] = [ 3, 2, 4, 1, 5, 0, 6 ];
        public readonly ColBits : number[] = [ 0x08, 0x10, 0x04, 0x20, 0x02, 0x40, 0x01 ];

        /// <summary> Number of Stones on Board. Importand for Even / Odd </summanty>
        private moves : number = 0;

        /// <summary>The Vectors for all Direction of evey Cell.</summary>
        public fourVec : FourVector[]  = new Array(Cols + 6 + 6 + Rows);

        // public boardScore : number[] = new Array(2);
        
        public constructor()
        {
            for (let i = 0; i < BoardCells; i++)
            {
                this.board[i] = new Cell(i);
            }
            PatternLookup.SetupPatternTable();
            this.GenerateVectors();
            this.AssignVectors();
            this.Init();
        }

        public Init() : void
        {
            this.moves = 0;
            for (let i = 0; i < Cols;i++)
            {
                this.height[i] = 0;
            }
            for (let i = 0; i < BoardCells; i++)
            {
                this.board[i].InitScores();
            }
        }

        get nbMoves(): number
        {
            return this.moves;
        }

        /// Indicates whether a column is playable.
        /// param col: 0-based index of column to play
		///	return true if the column is playable, false if the column is already full.
        public canPlay(col: number) : boolean
        {
            return this.height[col] < Rows;
        }

        // * Plays a playable column.
        // * This function should not be called on a non-playable column or a column making an alignment.
        // * @param col: 0-based index of a playable column.
        public play(col : number) : void
        {
            let index : number = (this.height[col] * Cols) + col;
            let color : Piece = (this.moves % 2 + 1); // even: Engine
            this.SetStone(ActionType.Add, index, color);
            this.moves++;
        }
     
       //  Plays a sequence of successive played columns, mainly used to initilize a board.
       //  param seq: a sequence of digits corresponding to the 1-based index of the column played.
       //
       //* return number of played moves.Processing will stop at first invalid move that can be:
       //       - invalid character (non digit, or digit >= WIDTH)
       //       - playing a colum the is already full
       //       - playing a column that makes an aligment(we only solve non).
       //       Caller can check if the move sequence was valid by comparing the number of
       //       processed moves to the length of the sequence.

       public playStones(seq : string) : number
       {
           for (let i = 0; i < seq.length; i++)
           {
               let col : number = parseInt(seq.substring(i,i + 1),10);
               col--;
               if (col < 0 || col >= Cols || !this.canPlay(col) || this.isWinningMove(col)) 
                   return i; // invalid move
               this.play(col);
           }
           return seq.length;
       }
        // This function should never be called on a non-playable column.
		// param col: 0-based index of a playable column.
		// return true if current player makes an alignment by playing the corresponding column col.
        public isWinningMove(col : number) : boolean
        {
            if (col < 0 || col >= Cols || this.height[col] >= Rows)
                throw new Error("isWinningMove. col: " + col.toString() + " out of range or collumn is full." );
            let index = (this.height[col] * Cols) + col;
            let cell : Cell = this.board[index];
            return cell.cellScores[this.moves % 2].score >= theFourth;
        }
        
        /// <summary>Check possible next Moves. 
        /// 1st: Is Winning / Loosing 
        /// 2nd: Is Next Loosing Col
        /// </summary>
        /// <param name="movableCol">Col is Selectable, not loosing next and no Winner </param>
        /// <param name="loosingNext">Col yields loose on next Move</param>
        /// <returns>true -> has Winner</returns>
        public MoveAnalyzer() : [ isWin : boolean, movable : number, loosingNext : number]
        {
            let maTupple : [ isWin : boolean, movable : number, loosingNext : Number];
            maTupple = [false, 0, 0];
            let movableCol = 0;
            let loosingNext = 0;
            let evenOdd = this.moves % 2;  // even / yellow = 0; odd / blue = 1
            // columnOrder 0 - 7:  3, 2, 4, 1, 5, 0, 6
            for (let i = 0; i < Cols; i++)
            {
                let col : number = this.columnOrder[i];       // i == 0 -> center col 3, i == 2 -> beside Center col  
                let h : number = this.height[col];
                if (h < Rows)
                {
                    // is Settable at all
                    let index : number = (this.height[col] * Cols) + col;
                    let cell : Cell = this.board[index];
                    if (cell.cellScores[evenOdd].score >= theFourth)
                    {
                        // Winning = true
                        movableCol = col;
                        loosingNext = 0;
                        return [true, movableCol, loosingNext];
                    }
                    if (h < (Rows - 1))
                    {
                        // at least 2 more stones this column possible
                        let indexUp : number = index + Cols;
                        let cellUp : Cell = this.board[indexUp];
                        if (cellUp.cellScores[1 - evenOdd].score >= theFourth)
                        {
                            // ColBits = { 0x08, 0x10, 0x04, 0x20, 0x02, 0x40, 0x01 };
                            loosingNext |= this.ColBits[i];
                            continue;
                        }
                    }
                    movableCol |= this.ColBits[i];
                }
            }
           return [false, movableCol, loosingNext];
        }

        private GetCellTotal(player : Player, index : number) : number
        {
            if (index >= 0 && index < (Rows * Cols))
                return this.board[index].cellScores[player].score;
            else
                throw new Error("GetCellTotal wrong Index.") ;
        }

        public JustSet(col:number) : number
        {   
            if (col >= 0 && this.height[col] > 0)
                return ((this.height[col] - 1) * Cols) + col;
            else 
                throw new Error("JustSet on invalid Column: " + col.toString());
        }

        // * UnDo a playabled column.
        // * @param col: 0-based index of a playable column.
        public unDo(col : number) : void
        {
            // Debug.Assert(height[col] > 0);
            let index : number = ((this.height[col] - 1) * Cols) + col;
            let color : Piece = this.board[index].piece; 
            this.SetStone(ActionType.UndoAdd, index, color);
            this.moves--;
        }



        /// <summary>Set Stone  at action Index.</summary>
        /// <param name="action">The action.</param>
        public Set(action : BoardAction ) : void
        {
            this.SetStone(action.Act, action.ToIndex, action.Color);
        }
        /// <summary>Sets the specified act.</summary>
        /// <param name="act">The action Code.</param>
        /// <param name="index">The index.</param>
        /// <param name="color">The color.</param>
        public SetStone(act :ActionType , index : number,  color : Piece) : void
        {
            if (index < 0 || index >= BoardCells)
                throw new Error("Index is not in Range 0 - 41") ;
            var cell : Cell = this.board[index];
            var row : number = Math.floor(index / Cols);
            var col : number = index % Cols;
            if (act == ActionType.Add || act == ActionType.AddTrace)
            {
                if (row != this.height[col])
                    throw new Error("SetStone invalid Index");
                if (cell.piece != Piece.Free)
                    throw new Error("SetStone Index is not Free");
                cell.piece = color;
                this.height[col] += 1;
            }
            else if (act == ActionType.UndoAdd)
            {
                if (row + 1 != this.height[col])
                    throw Error("SetStone UndoAdd Invalid Index.");
                if (cell.piece != color)
                    throw Error("SertStone Piece is not the specified Color");
                cell.piece = Piece.Free;
                this.height[col] -= 1;
            }
            this.UpdateCells(cell);
        }

        private UpdateCells(cell : Cell) : void
        { 
            // for every Direction
            for (let dir = 0; dir < cell.vectorIndex.length; dir++)
            {
                var vectorIndex = cell.vectorIndex[dir];
                if (vectorIndex == -1)
                {
                    continue;
                }
                // We have a Cell and a Vector
                var vector : FourVector  = this.fourVec[vectorIndex];
                var vectValue : number = vector.emptyVector;      // get Default with Outs set
                var slidingCellIndex : number = vector.FirstStoneIndex;
                var slidingBitIndex : number = vector.FirstBitIndex;
                // Now walk along the Vector and get the directions bit pattern
                for (let j = 0; j < vector.length; j++, slidingCellIndex += vector.incr, slidingBitIndex++) 
                {
                    var slidingCell : Cell = this.board[slidingCellIndex];
                    vectValue |= Board.bits[slidingBitIndex][slidingCell.piece];
                }
                // vectorValue has the Bit Pattern
                for (let player = 0;player < 2; player++)
                {
                    slidingCellIndex = vector.FirstStoneIndex;
                    slidingBitIndex = vector.FirstBitIndex;
                    for (let bit = 0; bit < vector.length; bit++,slidingCellIndex += vector.incr, slidingBitIndex++)
                    {
                        var dirVal : number = PatternLookup.PatternLookupTable[vectValue * 16 + player * 8 + slidingBitIndex];  
                        var cell1 : Cell = this.board[slidingCellIndex];
                        var score : CellScore = cell1.cellScores[player];
                        
                        var oldVal : number = score.detail[dir];
                        score.score -= oldVal;
                        // this.boardScore[player] = (this.boardScore[player] - oldVal);
                        
                        score.detail[dir] = dirVal;
                        score.score += dirVal;
                        // this.boardScore[player] = (this.boardScore[player] + dirVal);
                    }
                }
            }
        }

        public GetWinner(color : Piece, winner : number[]) : boolean
        {
            if (winner.length !=  4)
                throw new Error("GetWinner winner Array has not length 4");
            for (let j = 0; j < this.fourVec.length; j++)
            {
                var vector : FourVector  = this.fourVec[j];
                for (let maxWin = 0; maxWin < (vector.length - 3); maxWin++)
                {
                    var isWinner : boolean = true;
                    var startIndex : number = vector.FirstStoneIndex + (maxWin * vector.incr);
                    for (let i = 0;i < 4; i++, startIndex += vector.incr)
                    {
                        winner[i] = startIndex;
                        if (this.board[startIndex].piece != color)
                        {
                            isWinner = false;
                            break;
                        }
                    }
                    if (isWinner) return true;
                }
            }
            return false;
        }

 /*        public GetCellTotal(player : Player, index : number) : number
        {
            if (index < 0 || index >= BoardCells)
                throw new Error("GetCellTotal index out of Range.");
            return this.board[index].cellScores[player].score;
        }
 */
        private GenerateVectors() : void
        {
            // Generate 7 vertical Column Vectors 0 - 6
            for (let index = 0; index < Cols; index++)
            {
                var firstCol : number = (index % Cols);
                var firstRow : number = Math.floor(index / Cols);
                this.fourVec[index] = new FourVector(VectorDir.verticalDir, 6, this.dirIncr[VectorDir.verticalDir], index, 0, firstCol, firstRow, 0x3000);
            }
            // Generate 6 LeftRight Column Vectors 7 - 12
            this.fourVec[7] = new FourVector(VectorDir.leftRightDir, 4, this.dirIncr[VectorDir.leftRightDir], 14,2, 0, 2, 0x300F);
            this.fourVec[8] = new FourVector(VectorDir.leftRightDir, 5, this.dirIncr[VectorDir.leftRightDir], 7, 1, 0, 1, 0x3003);
            this.fourVec[9] = new FourVector(VectorDir.leftRightDir, 6, this.dirIncr[VectorDir.leftRightDir], 0, 0, 0, 0, 0x3000);
            this.fourVec[10] = new FourVector(VectorDir.leftRightDir, 6, this.dirIncr[VectorDir.leftRightDir], 1, 0, 1, 0,0x3000);
            this.fourVec[11] = new FourVector(VectorDir.leftRightDir, 5, this.dirIncr[VectorDir.leftRightDir], 2, 0, 2, 0,0x3C00);
            this.fourVec[12] = new FourVector(VectorDir.leftRightDir, 4, this.dirIncr[VectorDir.leftRightDir], 3, 0, 3, 0,0x3F00);

            // Generate 6 rightLeft Column Vectors 13 - 18
            this.fourVec[13] = new FourVector(VectorDir.rightLeftDir, 4, this.dirIncr[VectorDir.rightLeftDir], 3, 0, 3, 0, 0x3F00);
            this.fourVec[14] = new FourVector(VectorDir.rightLeftDir, 5, this.dirIncr[VectorDir.rightLeftDir], 4, 0, 4, 0, 0x3C00);
            this.fourVec[15] = new FourVector(VectorDir.rightLeftDir, 6, this.dirIncr[VectorDir.rightLeftDir], 5, 0, 5, 0, 0x3000);
            this.fourVec[16] = new FourVector(VectorDir.rightLeftDir, 6, this.dirIncr[VectorDir.rightLeftDir], 6, 0, 6, 0, 0x3000);
            this.fourVec[17] = new FourVector(VectorDir.rightLeftDir, 5, this.dirIncr[VectorDir.rightLeftDir], 13,1, 6, 1, 0x3003);
            this.fourVec[18] = new FourVector(VectorDir.rightLeftDir, 4, this.dirIncr[VectorDir.rightLeftDir], 20,2, 6, 2, 0x300F);

            // Generate 6 horizonal Column Vectors 19 - 24
            var fourVecIndex : number = 19;
            for (let index = 0; index < (Cols * Rows); index += Cols, fourVecIndex++)
            {
                var firstCol : number = (index % Cols);
                var firstRow : number = Math.floor(index / Cols);
                this.fourVec[fourVecIndex] = new FourVector(VectorDir.horizontalDir, 7, this.dirIncr[VectorDir.horizontalDir], index,0, firstCol, firstRow, 0x0000);
            }
        }
        private AssignVectors() : void
        {
            for(let i = 0; i < this.fourVec.length;i++)
            {
                var vector : FourVector = this.fourVec[i];
                var firstIndex : number = vector.FirstStoneIndex;
                for(let j = 0; j < vector.length; j++, firstIndex += vector.incr) 
                {
                    var cell : Cell = this.board[firstIndex];
                    cell.vectorIndex[vector.direction] = i;
                    if (vector.direction == VectorDir.horizontalDir)
                        cell.vectorStoneIndex[vector.direction] = j;
                    else
                        cell.vectorStoneIndex[vector.direction] = Math.floor(firstIndex / Cols);
                }
            }
        }

        public toString() : string
        {
            var text : string = "";
            for (let row = Rows - 1; row >= 0; row--)
            {
                text += Util.PadLeft(String(row * Cols),2) + ":  ";
                for (let col = 0; col < Cols; col++)
                { 
                    var index = row * Cols + col;
                    var cell : Cell = this.board[index];
                    var color = ".";
                    switch(cell.piece)
                    {
                        case Piece.Free:
                            break;
                        case Piece.Engine:
                            color = "E";
                            break;
                        case Piece.Opponent:
                            color = "O";
                            break;
                    }
                    var scoreEng : string = Util.PadLeft(String(cell.cellScores[0].score),2);
                    var scoreOpp : string = Util.PadLeft(String(cell.cellScores[1].score),2);
                   text += (color + " " + scoreEng + ":" + scoreOpp + "  ");   
                } 
                text += "\n";
            }
            return text;
        }
    }
