import { Shapes } from "./shapes";

export class Painter {

  public canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;

  private cellSize: number = 20;
  private yOff: number = 0;
  private xOff: number = 0;

  constructor(can: HTMLCanvasElement, con: CanvasRenderingContext2D) {
    this.canvas = can;
    this.context = con;
    this.sizeChangeEventHandler();    // set Canvas to ClientSize 
  }

  // Set Canvas Size to new Windows Size,  Calculate Cellsize , xOff, yOff
  public sizeChangeEventHandler = () => {
    let factor = 16;
    if (this.canvas.width != (window.innerWidth - factor) ||
      this.canvas.height != (window.innerHeight - factor)) {
      this.canvas.width = (window.innerWidth - factor);  // window.innerWidth;
      this.canvas.height = (window.innerHeight - factor); //  window.innerHeight;
      this.clearCanvas();
    }
    var w = Math.floor(this.canvas.width / 7);
    var h = Math.floor(this.canvas.height / 8);
    if (w < h) {
      this.cellSize = w;
    }
    else {
      this.cellSize = h;
    }
    this.yOff = Math.floor((this.canvas.height - (this.cellSize * 8)) / 2);
    this.xOff = Math.floor((this.canvas.width - (this.cellSize * 7)) / 2);
    //this.redraw();
  }

  public clearCanvas() {
    this.context.fillStyle = "gray";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  public IsInsideButton(n: number, mouseX: number, mouseY: number): boolean {
    var buttomCenter: [number, number] = this.ButtomCenter(n);
    return this.isInsideCricle(buttomCenter[0], buttomCenter[1], mouseX, mouseY,
      this.cellSize / 2);
  }


  private isInsideCricle(cx: number, cy: number, mx: number, my: number, radius: number): boolean {
    const dx = cx - mx;
    const dy = cy - my;
    const distance = Math.hypot(dx, dy);
    if (distance < radius)
      return true;
    return false;
  }


  private CellCenterX(index: number) {
    var col = index % 7;
    return this.xOff + (col * this.cellSize) + Math.floor(this.cellSize / 2);
  }

  // valid n = 1 - 3
  public ButtomCenter(n: number): [number, number] {
    if (n < 1 || n > 3)
      throw new Error("ButtomCenter: n not within 1 - 3.");
    let y = this.yOff + (7 * this.cellSize);
    let x = this.xOff + (this.cellSize + Math.floor(this.cellSize / 2)) + ((n - 1) * 2 * this.cellSize);
    return [x, y];
  }

  private TextCenter(n: number): [number, number] {
    let bc: [number, number] = this.ButtomCenter(n);
    return [bc[0], bc[1] + this.cellSize * 0.7]; // 0.7 cellsize below Buttoms
  }

  private CellCenterY(index: number) {
    var row = Math.floor(index / 7);
    var row = 5 - row
    return this.yOff + (row * this.cellSize) + Math.floor(this.cellSize / 2);
  }

  public GetIndex(x: number, y: number): number {
    if (x < this.xOff || x > this.xOff + (this.cellSize * 7))
      return -1;
    if (y < this.yOff || y > this.yOff + (this.cellSize * 6))
      return -1;
    let x2 = x;
    x = x - this.xOff;
    let col = Math.floor(x / this.cellSize);
    let xCol = this.xOff + (this.cellSize / 2) + (col * this.cellSize);
    if (x2 < (xCol - this.cellSize / 3) || x2 > (xCol + this.cellSize / 3))
      return -1;
    y = y - this.yOff;
    var row = Math.floor(y / this.cellSize);
    row = 5 - row;
    var index = row * 7 + col;
    return index;
  }

  public SetWinner(indices: number[]) {
    if (indices.length != 4)
      throw new Error("SetWinner not 4 indices.");
    for (let i = 0; i < indices.length; i++)
      this.drawGoldRing(indices[i]);
  }

  private drawGoldRing(i: number) {
    this.context.fillStyle = "gold";
    //this.context.lineWidth = Math.floor(this.cellSize / 10);
    this.context.beginPath();
    this.context.arc(this.CellCenterX(i), this.CellCenterY(i), Math.floor(this.cellSize / 10), 0, 2 * Math.PI);
    this.context.fill();
  }

  private drawBlackCore(i: number) {
    this.context.fillStyle = "black";
    this.context.beginPath();
    this.context.arc(this.CellCenterX(i), this.CellCenterY(i), Math.floor(this.cellSize / 8), 0, 2 * Math.PI);
    this.context.fill();
  }

  public redraw(board: number[], moves: number[], isEngineFirst: boolean, level: number) {
    this.clearCanvas();
    let context = this.context;
    let mcount: number = 0;
    for (let i = 0; i < board.length; ++i) {
      this.context.fillStyle = "white"; // default
      switch (board[i]) {
        case 0:
          context.fillStyle = "white";
          break;
        case 1:
          context.fillStyle = "red";
          mcount++;
          break;
        case 2:
          context.fillStyle = "blue";
          mcount++;
          break;
      }
      context.beginPath();
      context.arc(this.CellCenterX(i), this.CellCenterY(i), Math.floor(this.cellSize / 2.5), 0, 2 * Math.PI);
      context.fill();
    }
    if (mcount != moves.length)
      throw new Error("redraw: Stone Count on Board not equal to Moves Count");
    if (moves.length > 0) {
      let lastStone = moves[moves.length - 1];
      this.drawBlackCore(lastStone);
    }
    if (moves.length > 1) {
      let lastStone = moves[moves.length - 2];
      this.drawBlackCore(lastStone);
    }

    var startButtomCenter: [number, number] = this.ButtomCenter(1);
    context.beginPath();
    context.fillStyle = "white";
    context.arc(startButtomCenter[0], startButtomCenter[1], this.cellSize / 2, 0, 2 * Math.PI);
    context.fill();
    Shapes.drawRegularPolygon(context, 3, startButtomCenter[0], startButtomCenter[1], this.cellSize / 4);

    var beginnerButtomCenter: [number, number] = this.ButtomCenter(2);
    context.beginPath();
    context.fillStyle = "white";
    context.arc(beginnerButtomCenter[0], beginnerButtomCenter[1], this.cellSize / 2, 0, 2 * Math.PI);
    context.fill();
    if (isEngineFirst)
      Shapes.drawCogWheel(this.context, beginnerButtomCenter[0], beginnerButtomCenter[1], 7, this.cellSize / 4, this.cellSize / 5,
        this.cellSize / 10, this.cellSize / 12, this.cellSize / 15);
    else
      Shapes.drawHappyFace(context, beginnerButtomCenter[0], beginnerButtomCenter[1], this.cellSize / 4);

    this.redrawLevel(level);
    
    // Draw Buttom Test Line
    var xyText1 = this.TextCenter(1);
    context.font = (this.cellSize / 5).toString() + "px Arial";
    context.fillStyle = "black";
    context.textAlign = "center";
    context.fillText("Restart", xyText1[0], xyText1[1]);
    var xyText2 = this.TextCenter(2);
    context.fillText("Beginner", xyText2[0], xyText2[1]);
    var xyText3 = this.TextCenter(3);
    context.fillText("Level", xyText3[0], xyText3[1]);
  }

  public redrawLevel(level: number) {
    let context = this.context;
    var levelButtomCenter: [number, number] = this.ButtomCenter(3);
    context.beginPath();
    context.fillStyle = "white";
    context.arc(levelButtomCenter[0], levelButtomCenter[1], this.cellSize / 2, 0, 2 * Math.PI);
    context.fill();
    context.beginPath();
    context.fillStyle = "black";
    var LevelSize: number = this.cellSize / 16;
    switch (level) {
      case 1:
        break;
      case 2:
        LevelSize = this.cellSize / 6;
        break;
      case 3:
        LevelSize = this.cellSize / 3;
        break;
    }
    context.arc(levelButtomCenter[0], levelButtomCenter[1], LevelSize, 0, 2 * Math.PI);
    context.fill();
  }
}