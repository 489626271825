import { BoardCells, Cols, Rows} from "./globs";

export class Util
{
    private static ColLetter : string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    /// <summary>The pad left.</summary>
    /// <param name="text">The text.</param>
    /// <param name="len">The len.</param>
    /// <returns>The <see cref="string" />.</returns>
    public static PadLeft(text : string, len : number) : string
    {
        while (text.length < len)
        {
            text = " " + text;
        }
        return text;
    }
    
    /// <summary>Translate Index to text.</summary>
    /// <param name="index">The index.</param>
    /// <returns>EG: g10</returns>
    public static IndexToText(index : number) : string
    {
        if (index == -1)
        {
            return "-1";
        }
        if (index < 0 || index >= BoardCells)
        {
            return "xx";
        }
        var col : number = index % Cols;
        var row : number = index / Cols;
        var text : string = this.ColLetter[col];
        text += String(row + 1);
        return text;
    }

    /// <summary>Text ColRow like g10 to index.</summary>
    /// <param name="text"> The text. </param>
    /// <returns> The Board index wert. </returns>
    public static TextToIndex(text : string) : number
    {
        var t : string = text.toLowerCase().trim();
        if (t.length != 2)
        {
            throw new Error("TextToIndex. Parameter not 2 Letters.");
        }
        if (text == "-1")
        {
            return -1;
        }
        
        var letter : string = t.substring(0, 1).toUpperCase();
        var col : number = this.ColLetter.indexOf(letter);
        if (col < 0 || col > (7 - 1))
        {
            throw new Error("TextToIndex.Copl Letter out of Range.");
        }
        var numeric : string = t.substring(1);
        var row : number = parseInt(numeric);
        if (row < 1 || row > 6)
        {
            throw new Error("TextToInt. Numeric Part out of Range 1-6 .");
        }
        var index : number = (row - 1) * Cols + col;
        if (index < 0 || index > BoardCells)
        {
            console.log("Common::Util::TextToIndex. Text: " + text);
            throw new Error("TextToIndex. Text: " + text);
        }
        return index;
    }
}
