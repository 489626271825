// import { Shapes } from "./shapes";
import { Painter } from "./painter"
import { Searcher } from "./Searcher"
import { State, Rows, Cols, Piece } from "./globs"
import { Util } from "./Util";

var app: FourApp;

class FourApp {
  private paint!: Painter;
  private search!: Searcher;
  private board: number[] = new Array(42);
  private colCount: number[] = [0,0,0,0,0,0,0];
  private isEngineFirst : boolean = false;
  private level : number = 2; // valid Range 1 - 3
  private moves : number[] = [];  // indices of Moves from 0 - ... 
  private state : State = State.Idle;
  private depth : number[] = [4,4,6,10]; // level to depth
  private painting : boolean = false;

  constructor() {
    let canvas = document.getElementById('canvas') as
      HTMLCanvasElement;
    let context = canvas.getContext("2d");
    if (canvas == null)
    {
       throw new Error("Canvas Html Element not found.");
    }
    if (context == null)
      throw new Error("canvas.GetContext faild.");
    
    this.paint = new Painter(canvas, context);
    this.search = new Searcher();
    this.clearBoard();
    this.paint.redraw(this.board, this.moves, this.isEngineFirst, this.level);
    this.createUserEvents();
    // Initial State
    this.isEngineFirst = false; // let user start
    this.level = 2;
    this.state = State.WaitClick;
  }

  private stonesCount() : number {
    var n = 0;
    for (let i = 0; i < this.board.length; i++) {
      if (this.board[i] > 0)
        n++;
    }
    return n;
  }

  private clearBoard() {
    for (let i = 0; i < this.board.length; i++) {
      this.board[i] = 0;  // Empty
    }
    for (let i = 0; i < this.colCount.length; i++) {
      this.colCount[i] = 0;
    }
    this.paint.clearCanvas();
    this.moves = [];
    this.search.Init();
  }

  private createUserEvents() {
    //let canvas = this.canvas;

    this.paint.canvas.addEventListener("mousedown", this.pressEventHandler);
    this.paint.canvas.addEventListener("mouseup", this.releaseEventHandler);
 
    //this.paint.canvas.addEventListener("touchstart", this.pressEventHandler);
    //this.paint.canvas.addEventListener("touchend", this.releaseEventHandler);

    window.addEventListener('resize', this.sizeChangeEventHandler);
  }
 
  public sizeChangeEventHandler = () => {
    if (this.painting == false) 
    {
      this.painting = true;
      this.paint.sizeChangeEventHandler();
      this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
      this.painting = false;
    }
  }

  private releaseEventHandler = () => {
      // this.redraw();
  }
  
  // Handle Mouse or Touch Events
  private pressEventHandler = (e: MouseEvent | TouchEvent) => 
  {
    // Nur of MouseEvent reagieren Touch Event will also came as MouseEvent
    let mouseX = (e as TouchEvent).changedTouches ?
      (e as TouchEvent).changedTouches[0].pageX :
      (e as MouseEvent).pageX;
    let mouseY = (e as TouchEvent).changedTouches ?
      (e as TouchEvent).changedTouches[0].pageY :
      (e as MouseEvent).pageY;
    mouseX -= this.paint.canvas.offsetLeft;
    mouseY -= this.paint.canvas.offsetTop;

    let index = this.paint.GetIndex(mouseX, mouseY);
    if (index >= 0 && index < 42 && this.state == State.WaitClick)
    {
      let piece = 0;
      var col = index % 7;
      if (this.state != State.WaitClick || !this.search.canPlay(col))
        return;
      let userWin = this.search.isWinningMove(col);
      piece = this.SetStone(col);
      this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
      // winner or board full ?
      if (this.stonesCount() >= 42 || userWin)
      {
        if (userWin)
        {
          let winMoves : number[] = [-1,-1,-1,-1];
          this.search.GetWinner(piece, winMoves)
          this.paint.SetWinner(winMoves);
        }
        this.state = State.GameOver;
        return;
      }
      // Get Engine Move
      const start = performance.now();
      this.state = State.Searching;
      
      let sr = this.search.Search(this.depth[this.level]);
      
      let elapsed : number = (performance.now() - start);
      let engineWin : boolean = this.search.isWinningMove(sr[1]);
      piece = this.SetStone(sr[1]);
      console.log("Search. Depth: " + this.depth[this.level] + 
                  " Score: " + sr[0] +
                  " Nodes: " + sr[2].toString() + 
                  " ms: " + elapsed.toString().substring(0,6));
      this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
      if (this.stonesCount() >= 42 || engineWin)
      {
        if (engineWin)
        {
          let winMoves : number[] = [-1,-1,-1,-1];
          this.search.GetWinner(piece, winMoves)
          this.paint.SetWinner(winMoves);
        }
        this.state = State.GameOver;
        return; 
      }
      this.state = State.WaitClick ;
      return;
    }
    // ReStart ?
    if (this.paint.IsInsideButton(1,mouseX, mouseY))
    {
        if (this.stonesCount()  > 0)
        {
          this.clearBoard();
        }
        if (this.isEngineFirst)
        {
          this.SetStone(3);    // engine first Move 
        }
        this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
        this.state = State.WaitClick;
        return;
    }
    //  Toggle Beginner
    if (this.paint.IsInsideButton(2, mouseX, mouseY))
    {
      if (this.stonesCount() > 0)
      {
        this.clearBoard();
      }
      this.isEngineFirst = !this.isEngineFirst;
      if (this.isEngineFirst == true)
      {
        this.SetStone(3);    // engine first Move this.board[3] = 1;    // engine first Move         
      }
      this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
      this.state = State.WaitClick;
      return;
    }
    // Change Level ?
    if (this.paint.IsInsideButton(3, mouseX, mouseY))
    {
      switch(this.level)
      {
        case 1:
          this.level = 2;
          break;
        case 2:
          this.level = 3;
          break;
        case 3:
          this.level = 1;
          break; 
        default:
          this.level = 1;
          break;   
      }
      this.paint.redrawLevel(this.level);
      // this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
      return;
     }
     this.paint.redraw(this.board, this.moves, this.isEngineFirst,this.level);
     return;
  }

  /// Set Stone a given col und return Piece Color a num
  private SetStone(col: number) : number  {
    let piece = 0;
    if (this.colCount[col] < 6) {
      // var row = Math.floor(index / 7);
      var setIndex = col + (this.colCount[col] * 7);
      var even = this.stonesCount() % 2;
      if (even == 0) {
        this.board[setIndex] = 1;
        piece = 1;
      }
      else {
        this.board[setIndex] = 2;
        piece = 2;
      }
      this.colCount[col] = this.colCount[col] + 1;
      this.moves.push(setIndex);   // Add nodes Stone Index
    
      // Transfer moves to board.
      this.search.Init();     // Clear Board
      for (let i = 0;i < this.moves.length;i++)
      {
          // Debug.Assert(this.solver.canPlay(this.moves[i]));
          this.search.play(this.moves[i] % Cols);
      }
    }
    else
      throw new Error("SetStone on full Column: " + col.toString())
    return piece; 
  }
}

app = new FourApp();